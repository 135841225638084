import React, { useState, useRef } from "react";
import styles from "./styles.module.scss";
import useResponsiveQuery from "utils/useResponsiveQuery";
// import tabletBackground from "assets/images/tablet_main.jpg";
import tr from "utils/translator";
import icons from "assets/icons";

function HeadlineSection({ innerRef }: any) {
  const isMobile = useResponsiveQuery();
  const videoRef = useRef<HTMLVideoElement>(document.createElement("video"));
  const [mute, setMute] = useState(true);
  const titleContent = !isMobile
    ? tr.text("mainPage.title")
    : tr.text("mainPage.titleMobile");

  const paragraphContent = "Storycards is the world's first editor to build professional and custom engagement products in a completely visual canvas with no code.\n\nBoost your users' engagement, create smarter segments based on their choices and get better quality leads.";
  const desktopVideoLink = "https://player.vimeo.com/external/660359266.hd.mp4?s=181e2d4d58c43142b701c9803ed57f33cff6777b&profile_id=175";
  const mobileVideoLink = "https://player.vimeo.com/external/660359266.hd.mp4?s=181e2d4d58c43142b701c9803ed57f33cff6777b&profile_id=174";
  const videoSRC = isMobile ? mobileVideoLink : desktopVideoLink;

  const muteVideo = () => {
    setMute(true);
    videoRef.current.muted = true;
  };

  const unMute = () => {
    setMute(false);
    videoRef.current.muted = false;
  };
  return (
    <section
      ref={innerRef}
      className={`${styles.headlineSection} flex flex-col w-full items-center`}
    >
      {/* <h1 className={styles.title}>{titleContent}</h1> */}
      {/* <div className={styles.videoWrapper}>
        <div
          className={styles.tabletBackground}
          style={{
            background: `url(${tabletBackground}) 0% 0% / cover no-repeat`,
          }}
        ></div>
        <div className={styles.tabletShadow}></div>
        </div> */}
      <div className={`${styles.videoContainer}`}>
        <div className={styles.shadow}></div>
        <video
          playsInline
          autoPlay
          ref={videoRef}
          loop
          muted={mute}
          disableRemotePlayback
          src={videoSRC}
          className={mute ? styles.videoMuteCursor : styles.videoUnmuteCursor}
          onClick={() => setMute(!mute)}
        />
        <div className={styles.shadow2}></div>
        {isMobile && (
          <>
            {mute ? (
              <button
                type="button"
                className={styles.muteButton}
                onClick={unMute}
              >
                <img src={icons.mute} alt="ummute" />
              </button>
            ) : (
              <button
                type="button"
                className={styles.muteButton}
                onClick={muteVideo}
              >
                <img src={icons.unmute} alt="mute" />
              </button>
            )}
          </>
        )}
      </div>

      <div
        className={`${styles.content} flex flex-col md:flex-row items-center md:items-start text-center md:text-left`}
      >
        <h3 className={`font-semibold `}>{titleContent}</h3>
        <p className={`flex-1 whitespace-pre-wrap `}>{paragraphContent}</p>
      </div>
    </section>
  );
}

export default HeadlineSection;
