import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import {
  // SC_EDITOR_COPY_ROUTE,
  SC_EDITOR_ROUTE,
  ANALYTICS_ROUTE,
  SEGMENTS_ROUTE,
  COLLABORATION_ROUTE,
  FLOW_ROUTE,
  DATA_AND_PRIVACY_ROUTE,
  CARDS_FORMAT_ROUTE,
  INVITE_ROUTE,
  ABOUT_ROUTE,
  PRESS_CENTER_ROUTE,
  GIL_ROUTE,
  ROOT_ROUTE,
  NOT_FOUND_ROUTE,
  PRIVACY_POLICY_ROUTE,
  DPA_ROUTE,
  STARTUPS_ROUTE,
  PUBLISHERS_ROUTE,
  AGENCIES_ROUTE,
} from 'components/constants';
import Homepage from 'components/pages/Homepage';
import Flow from 'components/pages/Flow';
import Analytics from 'components/pages/Analytics';
// import SCEditorCopy from "components/pages/SCEditorCopy";
import Segments from 'components/pages/Segments';
import ScrollToTop from 'components/utils/ScrollToTop';
import EditorPage from 'components/pages/EditorPage';
import CardsFormat from 'components/pages/CardsFormat';
import DataAndPrivacy from 'components/pages/DataAndPrivacy';
import { PRICING_ROUTE } from 'components/constants';
import PricingPage from 'components/pages/PricingPage';
import About from 'components/pages/About';
import Invite from 'components/pages/Invite';
import Collaboration from 'components/pages/Collaboration';
import Meta from 'components/common/Meta';
import PressCenter from 'components/pages/PressCenter';
import GilPage from 'components/pages/GilPage';
import PageNotFound404 from 'components/pages/PageNotFound404';
import PrivacyPolicy from 'components/pages/PrivacyPolicy';
import DPA from 'components/pages/DPA';
import Startups from 'components/pages/Startups';
import Agencies from 'components/pages/Agencies';
import Publishers from 'components/pages/Publishers';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Meta />
      <Switch>
        <Route exact path={ROOT_ROUTE} component={Homepage} />
        <Route exact path={FLOW_ROUTE} component={Flow} />
        <Route exact path={ANALYTICS_ROUTE} component={Analytics} />
        <Route exact path={SEGMENTS_ROUTE} component={Segments} />
        <Route exact path={SC_EDITOR_ROUTE} component={EditorPage} />
        <Route exact path={DATA_AND_PRIVACY_ROUTE} component={DataAndPrivacy} />
        <Route exact path={CARDS_FORMAT_ROUTE} component={CardsFormat} />
        <Route exact path={ABOUT_ROUTE} component={About} />
        <Route exact path={PRICING_ROUTE} component={PricingPage} />
        <Route exact path={INVITE_ROUTE} component={Invite} />
        <Route exact path={COLLABORATION_ROUTE} component={Collaboration} />
        <Route exact path={GIL_ROUTE} component={GilPage} />
        <Route exact path={PRESS_CENTER_ROUTE} component={PressCenter} />
        <Route exact path={PRIVACY_POLICY_ROUTE} component={PrivacyPolicy} />
        <Route exact path={DPA_ROUTE} component={DPA} />
        <Route exact path={STARTUPS_ROUTE} component={Startups} />
        <Route exact path={AGENCIES_ROUTE} component={Agencies} />
        <Route exact path={PUBLISHERS_ROUTE} component={Publishers} />
        <Route exact path={NOT_FOUND_ROUTE} component={PageNotFound404} />
        <Redirect path="*" to={NOT_FOUND_ROUTE} />
      </Switch>
    </Router>
  );
}

export default App;
