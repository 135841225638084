import icons from 'assets/icons';
import { ROOT_ROUTE } from 'components/constants';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

function MobileLogo() {
  return (
    <h1 className={`${styles.mobileLogo} absolute top-0 z-50`}>
      <Link to={ROOT_ROUTE}>
        <img src={icons.storycardsLogoNoText} title="Storycards" alt="Storycards" />
      </Link>
    </h1>
  );
}

export default MobileLogo;
