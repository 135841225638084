import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

type Props = {
  page?: string;
};

function MetaContent({title, description, url}: { title: string; description: string; url: string }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="keywords" content="storycards,storycard,story,cards,editor"/>
      <meta name="description" content={description}/>
      <meta property="og:title" content={title}/>
      <meta content={description} property="og:description"/>
      <meta content={title} property="twitter:title"/>
      <meta content={description} property="twitter:description"/>
      <meta property="og:type" content="website"/>
      <meta content="summary_large_image" name="twitter:card"/>
      <meta property="og:type" content="website"/>
      <meta property="og:image" content="https://storycards.com/share.jpg"/>
      <meta name="twitter:image" content="https://storycards.com/share.jpg"/>
      <meta name="apple-mobile-web-app-capable" content="yes"/>
      <meta name="apple-mobile-web-app-title" content="Storycards: The no-code platform for engagement products"/>
      <meta name="robots" content="max-image-preview:large"/>
      <link rel="canonical" href={url}/>
      <meta property="og:url" content={url}/>
    </Helmet>
  )
}

function Meta({page}: Props) {
  const location = useLocation().pathname.slice(1);

  switch (location) {
    case '':
      return <MetaContent title="Storycards"
                          description="Storycards. The no-code platform for engagement products. Storycards is the worlds first editor to build professional and custom engagement products in a completely visual canvas with no code."
                          url="https://storycards.com"/>
    case 'flow':
      return <MetaContent title="Storycards - Flow"
                          description="Storycards Flow - With Storycards you can build multiple flows and create a unique experience according to the users' choices in each card."
                          url="https://storycards.com/flow"/>
    case 'editor':
      return <MetaContent title="Storycards - Editor"
                          description="Storycards - Let's meet Storycards Editor, The no-code platform for engagement products. Storycards is the worlds first editor to build professional and custom engagement products in a completely visual canvas with no code."
                          url="https://storycards.com/editor"/>
    case 'create-segments':
      return <MetaContent title="Storycards - Segments"
                          description="With Storycards Editor you create smarter segments based on users' choices and run personalized advertising campaigns. The ideal way to know more about your users and create a better relationship with them"
                          url="https://storycards.com/create-segments"
      />
    case 'pricing':
      return <MetaContent title="Storycards - Pricing"
                          description="Storycards Pricing - Choose the plan that works for you. Storycards. The no-code platform for engagement products."
                          url="https://storycards.com/pricing"
      />
    case 'cards-format':
      return <MetaContent title="Storycards - Cards Format"
                          description="Storycards - Engage your audience with a variety of content experiences that people love. Mix and match from a variety of our products such as Quiz, Poll, Yes No, Leads Form and more to create a unique engagement experience."
                          url="https://storycards.com/cards-format"
      />
    case 'collaboration':
      return <MetaContent title="Storycards - Collaboration"
                          description="Storycards - Storycards platform is great to work together with your team. Create your organization, Add your team and invite other collaborators to edit your story and see exactly who made any changes."
                          url="https://storycards.com/collaboration"
      />
    case 'analytics':
      return <MetaContent title="Storycards - Analytics"
                          description="Storycards analytics dashboard gives you all the information you need in order to understand the engagement level of your audience. "
                          url="https://storycards.com/analytics"
      />
    case 'data-collection':
      return <MetaContent title="Storycards - Data collection"
                          description="Storycards gives you the ability to choose what you want to do with your user data. Get more value and connect with your existing CRM or a third party marketing tool."
                          url="https://storycards.com/data-collection"
      />
    case 'invite':
      return <MetaContent title="Storycards - Invite"
                          description="Storycards. The no-code platform for engagement products. Storycards is the worlds first editor to build professional and custom engagement products in a completely visual canvas with no code."
                          url="https://storycards.com/invite"
      />
    case 'about':
      return <MetaContent title="Storycards - About"
                          description="Storycards - We started as Rabbi Interactive, a technology agency based in Tel Aviv in 2005. Over the past decade, we developed products that engaged over 10 million people every month"
                          url="https://storycards.com/about"
      />
    case 'press-center':
      return <MetaContent title="Storycards - Press Center"
                          description="View the latest news, announcements, and media resources from Storycards"
                          url="https://storycards.com/press-center"/>
    case 'privacy-policy':
      return <MetaContent title="Storycards - Privacy Policy"
                          description="Storycards. The no-code platform for engagement products. Storycards is the worlds first editor to build professional and custom engagement products in a completely visual canvas with no code."
                          url="https://storycards.com/privacy-policy"
      />
    case 'dpa':
      return <MetaContent title="Storycards - Data Processing Agreement For User"
                          description="Storycards. The no-code platform for engagement products. Storycards is the worlds first editor to build professional and custom engagement products in a completely visual canvas with no code."
                          url="https://storycards.com/dpa"
      />
    case 'startups':
      return <MetaContent title="Storycards - Startups and brands"
                          description="Storycards. The no-code platform for engagement products. Storycards is the worlds first editor to build professional and custom engagement products in a completely visual canvas with no code."
                          url="https://storycards.com/startups"
      />
    case 'agencies':
      return <MetaContent title="Storycards - Digital and advertising agencies"
                          description="Storycards. The no-code platform for engagement products. Storycards is the worlds first editor to build professional and custom engagement products in a completely visual canvas with no code."
                          url="https://storycards.com/agencies"
      />
    case 'publishers':
      return <MetaContent title="Storycards - Publishers"
                          description="Storycards. The no-code platform for engagement products. Storycards is the worlds first editor to build professional and custom engagement products in a completely visual canvas with no code."
                          url="https://storycards.com/publishers"
      />
    case 'gil-rabbi':
      return (
        <Helmet>
          <title>Gil Rabbi - Founder of Storycards</title>
          <meta name="keywords" content="gil,rabbi,gil rabbi,gil rabi"/>
          <meta
            name="description"
            content="Gil Rabbi is an engagement guru and the founder of Storycards. He is considered one of the global experts in creating digital products that improve engagement. Over the past decade, Rabbi developed products that engaged over 10 million people every month."
          />
          <meta property="og:title" content="Gil Rabbi - Founder of Storycards"/>
          <meta
            content="Gil Rabbi is an engagement guru and the founder of Storycards. He is considered one of the global experts in creating digital products that improve engagement. Over the past decade, Rabbi developed products that engaged over 10 million people every month."
            property="og:description"
          />
          <meta content="Gil Rabbi - Founder of Storycards" property="twitter:title"/>
          <meta
            content="Gil Rabbi is an engagement guru and the founder of Storycards. He is considered one of the global experts in creating digital products that improve engagement. Over the past decade, Rabbi developed products that engaged over 10 million people every month."
            property="twitter:description"
          />
          <meta content="summary_large_image" name="twitter:card"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://storycards.com/gilrabbi.jpg"/>
          <meta name="twitter:image" content="https://storycards.com/gilrabbi.jpg"/>
          <meta name="apple-mobile-web-app-capable" content="yes"/>
          <meta name="apple-mobile-web-app-title" content="Gil Rabbi - Founder of Storycards"/>
          <meta name="robots" content="max-image-preview:large"/>
          <link rel="canonical" href="https://storycards.com/gil-rabbi"/>
          <meta property="og:url" content="https://storycards.com/gil-rabbi"/>
        </Helmet>
      );
    default:
      return <MetaContent title="Storycards"
                          description="Storycards. The no-code platform for engagement products. Storycards is the worlds first editor to build professional and custom engagement products in a completely visual canvas with no code."
                          url="https://storycards.com"/>
  }
}

export default Meta;
