import React from 'react';
import cn from 'classnames';
import bigl from 'assets/images/clients/bigl.svg';
import elementor from 'assets/images/clients/elementor.svg';
import eurovision from 'assets/images/clients/eurovision.svg';
import n12 from 'assets/images/clients/n12.svg';
import mccann from 'assets/images/clients/mccann.svg';
import sport5 from 'assets/images/clients/sport5.svg';
import yad2 from 'assets/images/clients/yad2.svg';
import ynet from 'assets/images/clients/ynet.svg';
import mako from 'assets/images/clients/mako.svg';
import styles from './styles.module.scss';

const clients = [
  { src: mccann, href: 'https://mccann.co.il', id: 'mccann' },
  { src: eurovision, href: 'https://eurovision.tv', id: 'eurovision' },
  { src: elementor, href: 'https://elementor.com/', id: 'elementor' },
  { src: ynet, href: 'https://ynet.co.il', id: 'ynet' },
  { src: yad2, href: 'https://yad2.co.il', id: 'yad2' },
  { src: mako, href: 'http://mako.co.il', id: 'mako' },
  { src: n12, href: 'https://n12.co.il', id: 'n12' },
  { src: bigl, href: '', id: 'bigl' },
  { src: sport5, href: 'https://sport5.co.il', id: 'sport5' },
];

const ClientsSection = () => {
  return (
    <section className={cn(styles.clients, 'text-center')}>
      <p className="font-semibold text-left">
        Storycards engages more than 10,000,000 people every month and is trusted by teams of the world’s leading
        organizations
      </p>
      <div className="flex">
        <div className={cn(styles.list, 'flex', 'flex-shrink-0')}>
          <div className={cn(styles.listItems, 'grid', 'items-center', 'justify-items-start', 'flex-shrink-0')}>
            {clients.map((client) => (
              <img src={client.src} alt={client.id} key={`l1-client-logo-${client.id}`} />
            ))}
          </div>
          <div className={cn(styles.listItems, 'grid', 'items-center', 'justify-items-start', 'flex-shrink-0')}>
            {clients.map((client) => (
              <img src={client.src} alt={client.id} key={`l2-client-logo-${client.id}`} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientsSection;
