import React from "react";
import cx from "classnames";

import Header from "components/common/Header";
import Footer from "components/common/Footer";
import css from "./PageLayout.module.scss";

interface Props {
  className?: string;
  contentClassName?: string;
  children?: React.ReactNode;
  isTransparentHeader?: boolean;
  isFullVideo?: boolean;
}

const PageLayout: React.FC<Props> = ({
  className = "",
  contentClassName = "",
  children = null,
  isTransparentHeader = false,
  isFullVideo = false,
}: React.ComponentProps<typeof PageLayout>) => {
  return (
    <div className={cx(css.pageLayout, className)}>
      <Header isTransparentHeader={isTransparentHeader} />
      <div
        className={cx(
          css.contentWrap,
          isFullVideo ? css.fullVideo : "",
          contentClassName
        )}
      >
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default PageLayout;
